import Ground from '../assets/ground.png'
import WomanShooting from '../assets/home.jpg'
import PepperSpray from '../assets/pepper_spray.png'
import BabyTonfa from '../assets/baby_tonfa.png'
import Dog from '../assets/dog.png'
import Project from '../assets/project.png'
import Call1522 from '../assets/1522.png'

export const uiConstants = {
  sliders: [
    {
      id: 'Ground',
      image: Ground,
      title:
        'Scegli di non avere paura,\nscegli di non essere vulnerabile,\nscegli di essere il lupo',
      to: '/chi-siamo'
    },
    {
      id: 'Pepper',
      image: PepperSpray,
      title: 'Spray al peperoncino',
      description: 'Impara ad usarlo in sicurezza e con efficacia',
      to: '/progetto'
    },
    {
      id: 'BabyTonfa',
      image: BabyTonfa,
      title: 'Baby Tonfa',
      description: "Un portachiavi, ma anche un'arma. Ti può salvare la vita",
      to: '/progetto'
    },
    {
      id: 'Shooting',
      image: WomanShooting,
      title: 'Corsi di tiro per donne',
      description: 'Impara a proteggere te stessa e la tua famiglia',
      to: '/progetto'
    }
  ],
  social: [
    {
      icon: 'fa-brands fa-instagram',
      link: 'https://www.instagram.com/girlsdefense.it'
    },
    {
      icon: 'fa-brands fa-facebook',
      link: 'https://www.facebook.com/girlsdefense.it'
    },
    {
      icon: 'fa-solid fa-phone',
      link: 'tel:+393297634316'
    },
    {
      icon: 'fa-brands fa-whatsapp',
      link: 'https://wa.me/393297634316'
    },
    {
      icon: 'fa-regular fa-envelope',
      link: 'mailto:info@girlsdefense.it'
    }
  ],
  menu: [
    {
      label: 'Home',
      link: '/'
    },
    {
      label: 'Chi siamo',
      link: '/chi-siamo'
    },
    {
      label: 'Progetto',
      link: '/progetto'
    },
    {
      label: 'Vademecum',
      link: '/vademecum'
    },
    {
      label: 'Team',
      link: '/team'
    },
    {
      label: 'Foto',
      link: '/foto'
    },
    {
      label: 'Eventi',
      link: '/eventi'
    },
    {
      label: 'Partners',
      link: '/partners'
    }
  ],
  boxes: [
    {
      id: 'defendo',
      title: 'Chi Siamo',
      description:
        "Difesa personale con metodologia israeliana / krav maga, adatto per civili, forze dell'ordine e operatori della sicurezza.",
      to: '/chi-siamo',
      image: Dog
    },
    {
      id: 'casabella',
      title: 'Progetto',
      description:
        'Armeria Casabella si occupa da oltre 60 anni di commercio di armi, munizioni, abbigliamento, ottiche e materiale per ricarica delle migliori marche, nazionali ed estere.',
      to: '/progetto',
      image: Project
    },
    {
      id: 'killhouse',
      title: 'vademecum',
      description:
        "Una Kill House è una struttura utilizzata per simulare gli scontri ravvicinati negli ambienti urbani. Situata a Usmate Velate, in provincia di Monza e Brianza, con oltre 350 mq di spazi e un ampio parcheggio, offre attività di formazione inerenti la difesa personale e abitativa, con l'ausilio delle armi T4E Umarex.",
      to: '/vademecum',
      image: Call1522
    }
  ],
  contacts: [
    {
      name: 'name',
      label: 'Nome Cognome',
      required: true,
      type: 'text',
      placeholder: 'Nome Cognome'
    },
    {
      name: 'email',
      label: 'Email',
      required: true,
      type: 'email',
      placeholder: 'Email'
    },
    {
      name: 'phone',
      label: 'Telefono',
      required: false,
      type: 'tel',
      placeholder: 'Telefono'
    },
    {
      name: 'message',
      label: 'Messaggio',
      required: true,
      type: 'textarea',
      placeholder: 'Messaggio'
    }
  ],
  emailjs: {
    serviceId: 'service_ckqu7c9',
    templateId: 'template_q9ueoyp',
    publicKey: 'P1VqPMVbOZT7aq1ih'
  }
}
