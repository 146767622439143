import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyA2H3CXRrwZ3BHOlrYdKh5K8tO4VKEvE4A',
  authDomain: 'girlsdefense-d7ff9.firebaseapp.com',
  projectId: 'girlsdefense-d7ff9',
  storageBucket: 'girlsdefense-d7ff9.appspot.com',
  messagingSenderId: '350215421186',
  appId: '1:350215421186:web:08f731ac6de47a74bb161b',
  measurementId: 'G-V9QWQ0PF90'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
