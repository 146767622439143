import React, { Fragment, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'

import css from './Nav.module.scss'
import { uiConstants } from '../../../../constants'
import Logo from '../../../../assets/logo_white.svg'

const Nav = () => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleMenu = () => {
    setIsOpened(!isOpened)
  }

  return (
    <Fragment>
      <nav className={css.Nav}>
        <button
          className={`hamburger hamburger--spin-r ${
            isOpened ? 'is-active' : ''
          }`}
          aria-label='Menu'
          type='button'
          onClick={toggleMenu}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner'></span>
          </span>
        </button>
        <Link to='/' className={css.LogoLink}>
          <img src={Logo} alt='Logo' className={css.Logo} />
        </Link>
      </nav>
      <div
        className={`${css.MenuBackground} ${
          isOpened ? css.MenuBackgroundOpen : ''
        }`}
        onClick={toggleMenu}
      ></div>
      <div className={`${css.Menu} ${isOpened ? css.MenuOpen : ''}`}>
        <ul className={css.UlMenu}>
          {uiConstants.menu.map((m) => (
            <li key={m.link}>
              <NavLink
                onClick={toggleMenu}
                to={m.link}
                className={({ isActive }) =>
                  isActive ? css.NavActive : css.NavLink
                }
              >
                {m.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  )
}

export default Nav
