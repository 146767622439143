import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  loadEvents,
  loadPhotos,
  loadTeam,
  loadVideos,
  loadInstagram
} from '../../../../redux/actions'

const LoadCollections = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadPhotos())
    dispatch(loadVideos())
    dispatch(loadEvents())
    dispatch(loadInstagram())
    dispatch(loadTeam())
  }, [dispatch])

  return null
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  loadPhotos,
  loadEvents
})(LoadCollections)
