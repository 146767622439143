import { connect } from 'react-redux'

import Content from '../../../UI/Content/Content'
import css from './InstagramFeed.module.scss'
import Image from '../../../UI/Image/Image'

const InstagramFeed = ({ instagram }) => {
  return (
    <div className={css.Container}>
      <Content>
        <h1>@GirlsDefense.it</h1>
        <ul className={css.UlFeed}>
          <li>
            <h2>Seguici per rimanere aggiornato sulle nostre attività</h2>
            <p>
              Pubblichiamo costantemente foto e video delle nostre attività, ma
              anche consigli e suggerimenti per la tua sicurezza.
            </p>

            <a
              href='https://instagram.com/girlsdefense.it'
              target='_blank'
              rel='noreferrer'
              className={css.FollowUs}
            >
              Seguici su Instagram
            </a>
          </li>
          <li>
            {(instagram.list || []).map((i) => (
              <a
                key={i.id}
                href='https://instagram.com/girlsdefense.it'
                target='_blank'
                rel='noreferrer'
              >
                <Image src={i.image} alt={i.d} />
              </a>
            ))}
          </li>
        </ul>
      </Content>
    </div>
    // <div className={css.Container}>
    //
    // </div>
  )
}

export default connect((state) => {
  return state
}, {})(InstagramFeed)
