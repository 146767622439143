import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { photosRef } from '../../database'
import { onSnapshot } from 'firebase/firestore'

import { updatePhotos, removePhotos } from '../actions'

export let photosSnap

export function* loadPhotosSaga() {
  try {
    const listener = eventChannel((emit) => {
      photosSnap = onSnapshot(photosRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => photosSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updatePhotos({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updatePhotos({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removePhotos({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}
