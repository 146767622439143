import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import css from './Footer.module.scss'
import Partners from './Partners/Partners'
import { uiConstants } from '../../../../constants'

const Footer = () => {
  return (
    <Fragment>
      <footer className={css.Footer}>
        <ul className={css.UlContacts}>
          {uiConstants.social.map((s) => (
            <li key={s.link}>
              <a href={s.link} target='_blank' rel='noreferrer'>
                <i className={s.icon}></i>
              </a>
            </li>
          ))}
        </ul>
        #BeaWolf
        <br />
        <br />
        v.{' '}
        {document.head.querySelector('meta[name="build-version"]')['content']}
        <Partners />
      </footer>
      <div className={css.Copy}>
        <span>
          &copy;Home Defense {new Date().getFullYear()}. All Rights Reserved. -
          CF: 03786290134 - NCAGE AR088 - UNGM 688556
        </span>
        <span>
          <Link to='/privacy-policy' className={css.Link}>
            Privacy Policy
          </Link>
        </span>
      </div>
    </Fragment>
  )
}

export default Footer
