import React, { Fragment, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import stealth from '@stltio/stealth'

import css from './Layout.module.scss'

import Footer from './Footer/Footer'
// import ScrollTop from './ScrollTop/ScrollTop'
import Nav from './Nav/Nav'
import LoadCollections from './LoadCollections/LoadCollections'
import Contacts from './Contacts/Contacts'
import InstagramFeed from './InstagramFeed/InstagramFeed'
import NextEvent from './NextEvent/NextEvent'
import WhatsApp from './WhatsApp/WhatsApp'

const Layout = () => {
  let location = useLocation()

  useEffect(() => {
    stealth({
      apiKey: '597df45119327a80779f9a1139b4660d169d6315d9ee0fbc1c2b5f21283d58a0'
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Fragment>
      <NextEvent />
      <Nav />
      <main className={css.Main}>
        <Outlet />
      </main>
      <InstagramFeed />
      <Contacts />
      <Footer />
      {/* <ScrollTop /> */}
      <WhatsApp />
      <LoadCollections />
    </Fragment>
  )
}

export default Layout
