import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import css from './NextEvent.module.scss'
import { timeHelper } from '../../../../helpers'

const NextEvent = ({ events }) => {
  if ((events.list || []).length === 0) {
    return null
  }

  const e = events.list[0]
  return (
    <div className={css.NextEvent}>
      <Link to='/eventi' className={css.Link}>
        <i className='fa-solid fa-calendar-day'></i>
        {timeHelper.timeToDate(e.startDate)} &bull; {e.title}
      </Link>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(NextEvent)
