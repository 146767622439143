import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'

import PageLoader from './components/UI/PageLoader/PageLoader'
import Layout from './components/Containers/Layout/Layout'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/London')

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const WhoWeAreLazy = lazy(() => import('./components/Pages/WhoWeAre/WhoWeAre'))
const PhotoLazy = lazy(() => import('./components/Pages/Photo/Photo'))
const ProjectLazy = lazy(() => import('./components/Pages/Project/Project'))
const TeamLazy = lazy(() => import('./components/Pages/Team/Team'))
const EventsLazy = lazy(() => import('./components/Pages/Events/Events'))
const PrivacyPolicyLazy = lazy(() =>
  import('./components/Pages/PrivacyPolicy/PrivacyPolicy')
)
const VademecumLazy = lazy(() =>
  import('./components/Pages/Vademecum/Vademecum')
)
const PartnersLazy = lazy(() => import('./components/Pages/Partners/Partners'))

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomeLazy />} />
              <Route path='chi-siamo' element={<WhoWeAreLazy />} />
              <Route path='team' element={<TeamLazy />} />
              <Route path='progetto' element={<ProjectLazy />} />
              <Route path='eventi' element={<EventsLazy />} />
              <Route path='foto' element={<PhotoLazy />} />
              <Route path='vademecum' element={<VademecumLazy />} />
              <Route path='partners' element={<PartnersLazy />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyLazy />} />
              <Route path='*' element={<HomeLazy />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
