import { instagramConstants } from '../constants'

export const loadInstagram = () => {
  return {
    type: instagramConstants.INSTAGRAM_INIT
  }
}

export const updateInstagram = (payload) => {
  return {
    type: instagramConstants.INSTAGRAM_UPDATE,
    payload
  }
}

export const removeInstagram = (payload) => {
  return {
    type: instagramConstants.INSTAGRAM_REMOVE,
    payload
  }
}
