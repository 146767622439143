import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { videosRef } from '../../database'
import { onSnapshot } from 'firebase/firestore'

import { updateVideos, removeVideos } from '../actions'

export let videosSnap

export function* loadVideosSaga() {
  try {
    const listener = eventChannel((emit) => {
      videosSnap = onSnapshot(videosRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => videosSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateVideos({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateVideos({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeVideos({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}
