import css from './Partners.module.scss'

import HomeDefense from '../../../../../assets/homedefense.svg'
import Casabella from '../../../../../assets/casabella.svg'
import Defendo from '../../../../../assets/dkm_lion_white.svg'
import TactiClub from '../../../../../assets/tacticlub.svg'
import KillHouse from '../../../../../assets/killhouse.svg'

const Partners = () => (
  <ul className={css.UlPartners}>
    <li>
      <a
        href='https://homedefense.it'
        target='_blank'
        rel='noopener noreferrer'
        className={css.LnkPartner}
      >
        <img src={HomeDefense} alt='home defense' />
      </a>
    </li>
    <li>
      <a
        href='https://armeriacasabella.it/'
        target='_blank'
        rel='noopener noreferrer'
        className={css.LnkPartner}
      >
        <img src={Casabella} alt='Casabella' />
      </a>
    </li>
    <li>
      <a
        href='https://killhouse.it'
        rel='noopener noreferrer'
        target='_blank'
        className={css.LnkPartner}
      >
        <img src={KillHouse} alt='Kill House' />
      </a>
    </li>
    <li>
      <a
        href='https://defendokravmaga.it'
        target='_blank'
        rel='noopener noreferrer'
        className={css.LnkPartner}
      >
        <img src={Defendo} alt='Defendo Krav Maga' />
      </a>
    </li>
    <li>
      <a
        href='https://tacti.club'
        target='_blank'
        rel='noopener noreferrer'
        className={css.LnkPartner}
      >
        <img src={TactiClub} alt='Tacti.Club' />
      </a>
    </li>
  </ul>
)

export default Partners
