import { db } from './firestore'
import { collection } from 'firebase/firestore'
import { firestoreConstants } from './constants'

export const database = db

export const eventsRef = collection(db, firestoreConstants.events)
export const photosRef = collection(db, firestoreConstants.photos)
export const videosRef = collection(db, firestoreConstants.videos)
export const teamRef = collection(db, firestoreConstants.team)
export const instagramRef = collection(db, firestoreConstants.instagram)
