import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { thunk } from 'redux-thunk'

import createSagaMiddleware from 'redux-saga'

import {
  watchEvents,
  watchVideos,
  watchPhotos,
  watchTeam,
  watchInstagram
} from './sagas'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production'
})

sagaMiddleware.run(watchEvents)
sagaMiddleware.run(watchVideos)
sagaMiddleware.run(watchPhotos)
sagaMiddleware.run(watchTeam)
sagaMiddleware.run(watchInstagram)
