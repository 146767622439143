import { takeEvery } from 'redux-saga/effects'
import {
  photosConstants,
  eventsConstants,
  videosConstants,
  teamConstants,
  instagramConstants
} from '../constants'
import { loadPhotosSaga } from './photos.sagas'
import { loadEventsSaga } from './events.sagas'
import { loadVideosSaga } from './videos.sagas'
import { loadTeamSaga } from './team.sagas'
import { loadInstagramSaga } from './instagram.sagas'

export function* watchPhotos() {
  yield takeEvery(photosConstants.PHOTOS_INIT, loadPhotosSaga)
}

export function* watchVideos() {
  yield takeEvery(videosConstants.VIDEOS_INIT, loadVideosSaga)
}

export function* watchEvents() {
  yield takeEvery(eventsConstants.EVENTS_INIT, loadEventsSaga)
}

export function* watchTeam() {
  yield takeEvery(teamConstants.TEAM_INIT, loadTeamSaga)
}

export function* watchInstagram() {
  yield takeEvery(instagramConstants.INSTAGRAM_INIT, loadInstagramSaga)
}
