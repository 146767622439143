import { videosConstants } from '../constants'

export const loadVideos = () => {
  return {
    type: videosConstants.VIDEOS_INIT
  }
}

export const updateVideos = (payload) => {
  return {
    type: videosConstants.VIDEOS_UPDATE,
    payload
  }
}

export const removeVideos = (payload) => {
  return {
    type: videosConstants.VIDEOS_REMOVE,
    payload
  }
}
