import { combineReducers } from 'redux'

import photos from './photos.reducer'
import events from './events.reducer'
import videos from './videos.reducer'
import team from './team.reducer'
import instagram from './instagram.reducer'

const rootReducer = combineReducers({
  photos,
  events,
  videos,
  team,
  instagram
})

export default rootReducer
