import { photosConstants } from '../constants'

export const loadPhotos = () => {
  return {
    type: photosConstants.PHOTOS_INIT
  }
}

export const updatePhotos = (payload) => {
  return {
    type: photosConstants.PHOTOS_UPDATE,
    payload
  }
}

export const removePhotos = (payload) => {
  return {
    type: photosConstants.PHOTOS_REMOVE,
    payload
  }
}
