import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'

import css from './Contacts.module.scss'
import { uiConstants } from '../../../../constants'
import Content from '../../../UI/Content/Content'

const Contacts = () => {
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm()

  const onSubmit = (data) => {
    setSending(true)
    emailjs
      .send(
        uiConstants.emailjs.serviceId,
        uiConstants.emailjs.templateId,
        data,
        uiConstants.emailjs.publicKey
      )
      .then(() => {
        setMessage(
          'Messaggio inviato con successo, ti ricontatteremo al più presto.'
        )
      })
      .catch(() => {
        setMessage("Errore nell'invio del messaggio")
      })
      .finally(() => {
        setSending(false)
        reset()
        setTimeout(() => {
          setMessage('')
        }, 5000)
      })
  }

  const renderField = (f) => {
    switch (f.type) {
      case 'textarea':
        return (
          <textarea
            name={f.name}
            {...register(f.name, {
              required: f.required || false,
              validate: f.validate
            })}
          />
        )
      default:
        return (
          <input
            name={f.name}
            type={f.type}
            {...register(f.name, {
              required: f.required || false,
              validate: f.validate
            })}
          />
        )
    }
  }

  return (
    <div className={css.Container}>
      <Content>
        <h1>Contattaci</h1>
        Vuoi sapere tutto del nostro progetto?
        <br />
        Stai cercando il corso più vicino a te?
        <br />
        Necessiti di lezioni private?
        <br />
        Desideri organizzare un evento o un corso nella tua città o azienda?
        <br />
        <br />
        <a href='tel:+393297634316' className='common-link'>
          <i className='fa-solid fa-phone'></i>
          Francesca
        </a>
        <h2 className='mt'>Oppure Scrivici</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {uiConstants.contacts.map((f) => {
            return (
              <label key={f.name}>
                {f.label} {f.required && '*'}
                {renderField(f)}
              </label>
            )
          })}
          <div className={css.Privacy}>
            Inviando questo messaggio accetti la nostra{' '}
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </div>
          <button
            disabled={!isValid || sending}
            type='submit'
            aria-label='Save'
            className={css.Submit}
          >
            {sending ? 'Invio in corso...' : 'Invia messaggio'}
          </button>
          <div className={css.Message}>{message}</div>
        </form>
      </Content>
    </div>
  )
}

export default Contacts
